import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {
  blueglass,
  cloud,
  dusk,
  eggshell,
  heather,
  midnight,
  white,
} from 'src/constants/colors';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { ReactComponent as IconChevronDown } from '../../assets/icn-chevron-down.svg';
import { FilterPreviewCard } from './FilterPreviewCard';
import { NotifiTooltip } from '@components/NotifiTooltip';
import {
  ALERT_FREQUENCY_ITEMS,
  ALERT_FREQUENCY_LABELS,
} from '@components/cardConfig/form/topicTypes/AlertFrequencySelectField';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import {
  AlertFilter,
  FrequencyFilter,
  FusionEventMetadata,
} from './CreateNonCMTopicDialog';
import { checkboxCustomCSS } from '@components/cardConfig/common/CheckboxCSS';
import { InputWithLabel } from '@components/input/InputWithLabel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export type ThresholdTypeItem = 'integer' | 'float';

export const THRESHOLD_TYPE_LABELS = {
  integer: 'Integer',
  float: 'Float',
};

export const THRESHOLD_TYPE_ITEMS = Object.keys(THRESHOLD_TYPE_LABELS).map(
  (key) => key as ThresholdTypeItem,
);

export type FilterTypeItem =
  | 'aboveThreshold'
  | 'belowThreshold'
  | 'aboveOrBelowThreshold';

export const FILTER_TYPE_LABELS = {
  SelectOptionalFilter: '-- Select Optional Filter --',
  aboveThreshold: 'Above Threshold',
  belowThreshold: 'Below Threshold',
  aboveOrBelowThreshold: 'Above or Below Threshold',
};

export const FILTER_TYPE_ITEMS = Object.keys(FILTER_TYPE_LABELS).map(
  (key) => key as FilterTypeItem,
);

const frequencyConvertToMinutes = (frequency: string) => {
  switch (frequency) {
    case 'ALWAYS':
      return -1;
    case 'SINGLE':
      return 0;
    case 'ONE_MINUTE':
      return 1;
    case 'THREE_MINUTES':
      return 3;
    case 'FIVE_MINUTES':
      return 5;
    case 'QUARTER_HOUR':
      return 15;
    case 'HOURLY':
      return 60;
    case 'DAILY':
      return 1440;
    case 'WEEKLY':
      return 10080;
    default:
      return -1;
  }
};

const minutesConvertToFrequency = (minutes: number) => {
  switch (minutes) {
    case -1:
      return 'ALWAYS';
    case 0:
      return 'SINGLE';
    case 1:
      return 'ONE_MINUTE';
    case 3:
      return 'THREE_MINUTES';
    case 5:
      return 'FIVE_MINUTES';
    case 15:
      return 'QUARTER_HOUR';
    case 60:
      return 'HOURLY';
    case 1440:
      return 'DAILY';
    case 10080:
      return 'WEEKLY';
    default:
      return 'ALWAYS';
  }
};

type Props = {
  isEdit?: boolean;
  topicId?: string;
  topicName: string;
  setTopicName: (topicName: string) => void;
  filter: AlertFilter;
  setFilter: (filters: AlertFilter) => void;
  frequency: FrequencyFilter;
  setFrequency: (frequency: FrequencyFilter) => void;
  uiConfigOverride: FusionEventMetadata['uiConfigOverride'];
  setUiConfigOverride: (
    uiConfigOverride: FusionEventMetadata['uiConfigOverride'],
  ) => void;
  formHeader?: string;
  userPresetThresholdsEnabled: boolean;
  setUserPresetThresholdsEnabled: (val: boolean) => void;
};

const CreateNonCMTopicStep1Card = ({
  isEdit,
  topicName,
  setTopicName,
  filter,
  setFilter,
  frequency,
  setFrequency,
  uiConfigOverride,
  setUiConfigOverride,
  userPresetThresholdsEnabled,
  setUserPresetThresholdsEnabled,
}: Props) => {
  const isUserInputParamsWithRadioTypeandCorrectOrder =
    filter?.userInputParams[0]?.uiType === 'radio';
  const prefixValue =
    filter.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].prefixAndSuffix?.prefix;
  const suffixValue =
    filter.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].prefixAndSuffix?.suffix;
  const prefixAndSuffixValue = prefixValue || suffixValue || '';
  const allowCustomInput =
    filter.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].allowCustomInput;

  const { isAP2Enabled, canFilterOnTag } = useFeatureFlag();
  const [userInput, setUserInput] = React.useState(
    isEdit
      ? uiConfigOverride?.subscriptionValueOrRef?.type === 'ref' &&
        uiConfigOverride?.isSubscriptionValueInputable
        ? uiConfigOverride?.subscriptionValueOrRef.ref
        : ''
      : '',
  );

  const [isUserSelectsSelect, setIsUserSelectsSelect] = React.useState(
    uiConfigOverride?.isSubscriptionValueInputable ? true : false,
  );
  const [customizedThresholdType, setCustomizedThresholdType] = React.useState<
    'Prefix' | 'Suffix' | undefined
  >(prefixValue ? 'Prefix' : suffixValue ? 'Suffix' : undefined);

  const { clearMessageBarState } = useFormMessageBarContext();

  const handleFilterChange = (thresholdDirection: string) => {
    const nextState = { ...filter };
    if (thresholdDirection === 'SelectOptionalFilter') {
      nextState.name = '';
      nextState.userInputParams[1].kind = 'integer';
      nextState.requiredParserVariables[0].variableType = 'integer';
      nextState.userInputParams[1].options = [];
      nextState.userInputParams[1].defaultValue = '';
      nextState.description = '';
      nextState.userInputParams[1].prefixAndSuffix = { prefix: '', suffix: '' };
      setFilter(nextState);
      return;
    }
    // TODO: All of these should be read from server side defined alert and source filter types
    // TODO: that are supported as native filters by the system.
    nextState.name = thresholdDirection;
    nextState.executionPriority =
      thresholdDirection === 'aboveThreshold'
        ? 20
        : thresholdDirection === 'belowThreshold'
        ? 21
        : 22;
    nextState.userInputParams[1].allowCustomInput = true;
    nextState.userInputParams[1].uiType = 'button';
    const uiParamsKind = filter.userInputParams[1].kind.toLowerCase();

    if (uiParamsKind === 'float') {
      nextState.userInputParams[1].options = [0.5, 0.6];
      nextState.userInputParams[1].kind = uiParamsKind;
      nextState.userInputParams[1].defaultValue = 0.5;
      nextState.requiredParserVariables[0].variableType = uiParamsKind;
    } else if (uiParamsKind === 'integer') {
      nextState.userInputParams[1].options = [50, 60];
      nextState.userInputParams[1].kind = uiParamsKind;
      nextState.userInputParams[1].defaultValue = 50;
      nextState.requiredParserVariables[0].variableType = uiParamsKind;
    }

    if (thresholdDirection === 'aboveOrBelowThreshold') {
      nextState.userInputParams[0].options = ['above', 'below'];
      nextState.userInputParams[0].description =
        'Alert me when value is above or below:';
      nextState.description = 'Alert me when value is above or below:';
    } else if (thresholdDirection === 'aboveThreshold') {
      nextState.userInputParams[0].options = [];
      nextState.userInputParams[0].defaultValue = 'above';
      nextState.userInputParams[0].description =
        'Alert me when value is above:';
      nextState.description = 'Alert me when value is above:';
    } else if (thresholdDirection === 'belowThreshold') {
      nextState.userInputParams[0].options = [];
      nextState.userInputParams[0].defaultValue = 'below';
      nextState.userInputParams[0].description =
        'Alert me when value is below:';
      nextState.description = 'Alert me when value is below:';
    }
    setFilter(nextState);
    setUserPresetThresholdsEnabled(true);
  };

  const updateThresholdType = (
    filterPayload: AlertFilter,
    thresholdType?: 'float' | 'integer' | 'resetOptions' | 'reset',
  ) => {
    const kind =
      thresholdType ??
      filter.userInputParams[
        isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
      ].kind.toLowerCase();
    const userInputParamsItem =
      filterPayload.userInputParams[
        isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
      ];
    if (kind === 'reset') {
      filterPayload.name = '';
      filterPayload.description = '';
      userInputParamsItem.options = [];
      userInputParamsItem.defaultValue = '';
      userInputParamsItem.kind = 'integer';
      userInputParamsItem.prefixAndSuffix = {
        prefix: '',
        suffix: '',
      };
      filterPayload.requiredParserVariables[0].variableType = 'integer';
    }

    if (kind === 'float') {
      userInputParamsItem.options = [0.5, 0.6];
      userInputParamsItem.defaultValue = 0.5;
      userInputParamsItem.kind = kind;
      filterPayload.requiredParserVariables[0].variableType = kind;
    }

    if (kind === 'integer') {
      userInputParamsItem.options = [50, 60];
      userInputParamsItem.defaultValue = 50;
      userInputParamsItem.kind = kind;
      filterPayload.requiredParserVariables[0].variableType = kind;
    }

    if (kind === 'resetOptions') {
      userInputParamsItem.options = ['', ''];
      userInputParamsItem.defaultValue = '';
    }

    return filterPayload;
  };

  const showVariableNameValue = () => {
    return 'thresholdComparisonValue';
  };

  const setRadioGroupDefaultValue = () => {
    if (uiConfigOverride?.isSubscriptionValueInputable) {
      return 'select';
    } else {
      if (
        uiConfigOverride?.subscriptionValueOrRef?.type === 'value' &&
        uiConfigOverride?.subscriptionValueOrRef?.value[0].value === '*'
      ) {
        return 'none';
      } else if (
        uiConfigOverride?.subscriptionValueOrRef?.type === 'ref' &&
        uiConfigOverride?.subscriptionValueOrRef?.ref === 'walletAddress'
      ) {
        return 'wallet';
      }
    }
  };

  const topicInfoRef = React.useRef(null);
  const SubscriberDataRef = React.useRef(null);

  const [activeSection, setActiveSection] = React.useState<string | null>(null);

  const handleScrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  React.useEffect(() => {
    const sections = [
      { id: 'topicInfo', ref: topicInfoRef },
      { id: 'subscriberData', ref: SubscriberDataRef },
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 },
    );

    sections.forEach((section) => {
      if (section.ref.current) {
        observer.observe(section.ref.current);
      }
    });

    return () => {
      sections.forEach((section) => {
        if (section.ref.current) {
          observer.unobserve(section.ref.current);
        }
      });
    };
  }, []);

  return (
    <div>
      <Stack flexDirection="row" gap={2}>
        <Box sx={{ width: '367px', position: 'relative' }}>
          <Box
            sx={{
              position: 'fixed',
              backgroundColor: '#FEFEFE',
              width: '367px',
              borderRadius: '8px',
              p: 4,
            }}
          >
            <Typography
              onClick={() => handleScrollToSection(topicInfoRef)}
              fontSize={'16px'}
              color={activeSection === 'topicInfo' ? midnight : dusk}
              sx={{
                cursor: 'pointer',
                position: 'relative',
                '&::before': {
                  content: '""',
                  display:
                    activeSection === 'topicInfo' ? 'inline-block' : 'none',
                  position: 'absolute',
                  left: '-15px', // Adjust as needed
                  top: '40%',
                  transform: 'translateY(-50%)',
                  width: '6px', // Adjust as needed
                  height: '6px', // Adjust as needed
                  borderRadius: '50%',
                  backgroundColor: blueglass, // Adjust color as needed
                },
              }}
            >
              Topic Information
            </Typography>
            <Typography
              onClick={() => handleScrollToSection(SubscriberDataRef)}
              fontSize={'16px'}
              color={activeSection === 'subscriberData' ? midnight : dusk}
              sx={{
                mt: 1,
                cursor: 'pointer',
                position: 'relative',
                '&::before': {
                  content: '""',
                  display:
                    activeSection === 'subscriberData'
                      ? 'inline-block'
                      : 'none',
                  position: 'absolute',
                  left: '-15px', // Adjust as needed
                  top: '40%',
                  transform: 'translateY(-50%)',
                  width: '6px', // Adjust as needed
                  height: '6px', // Adjust as needed
                  borderRadius: '50%',
                  backgroundColor: blueglass, // Adjust color as needed
                },
              }}
            >
              Subscriber Data & Filters
            </Typography>
            <Box
              sx={{
                width: '100%',
                height: '1px',
                backgroundColor: heather,
                my: 2.5,
              }}
            />

            <Stack direction={'row'} gap={2}>
              <Typography variant="body2" color={midnight} fontWeight={700}>
                Need some guidance?
              </Typography>
              <Stack
                sx={{ display: 'flex', flexDirection: 'row' }}
                onClick={() =>
                  window.open(
                    'https://docs.notifi.network/docs/create-topics/api-triggered',
                    '_blank',
                  )
                }
              >
                <Typography
                  sx={{
                    color: blueglass,
                    fontSize: '14px',
                    fontWeight: 700,
                    p: 0,
                    cursor: 'pointer',
                  }}
                >
                  Docs
                </Typography>
                <OpenInNewIcon
                  sx={{
                    color: blueglass,
                    fontSize: '16px',
                    p: 0,
                    cursor: 'pointer',
                    marginLeft: '6px',
                    fontWeight: 'bold',
                  }}
                />
              </Stack>
            </Stack>
          </Box>
        </Box>
        <Stack flexDirection="column">
          <Stack
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: white,
              borderRadius: '8px',
              mb: 3,
              p: 4,
            }}
          >
            <Typography
              sx={{
                pb: 3,
                width: '100%',
              }}
              variant="h2"
              id="topicInfo"
              ref={topicInfoRef}
            >
              Topic Information
            </Typography>
            <InputWithLabel
              isEdit={isEdit}
              value={topicName ?? ''}
              handleChange={(e) => {
                const value = (e.target as HTMLInputElement).value;
                setTopicName(value);
              }}
              label="Name this topic"
              customSx={{ width: '360px', mb: '12px' }}
            />
            <InputWithLabel
              isEdit={isEdit}
              value={uiConfigOverride?.topicDisplayName ?? ''}
              handleChange={(e) => {
                const nextState = { ...uiConfigOverride };
                nextState.topicDisplayName = e.target.value;
                setUiConfigOverride(nextState);
              }}
              label="Topic display name"
              customSx={{
                width: '360px',
                mb: '12px',
                mt: isEdit ? '0px' : '16px',
              }}
              tooltipContent="This display title is used in the card when users are subscribing, for example: ‘Liquidation Alerts'.
            We recommend these titles be plural."
            />
            <InputWithLabel
              isEdit={isEdit}
              value={uiConfigOverride?.historyDisplayName ?? ''}
              handleChange={(e) => {
                const nextState = { ...uiConfigOverride };
                nextState.historyDisplayName = e.target.value;
                setUiConfigOverride(nextState);
              }}
              label="Topic display title in alert"
              customSx={{
                width: '360px',
                mb: '12px',
                mt: isEdit ? '0px' : '16px',
              }}
              tooltipContent="This display title is used in the alert received, for example: ‘Liquidation Alert’.
            We recommend these titles be singular."
            />
          </Stack>

          <Stack
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: white,
              borderRadius: '8px',
              mb: 3,
              p: 4,
            }}
            id="subscriberData"
            ref={SubscriberDataRef}
          >
            <Stack
              flexDirection="column"
              justifyContent="space-between"
              alignItems="start"
              sx={{
                my: 2,
                width: '360px',
              }}
            >
              <Typography
                sx={{
                  pb: 3,
                  width: '100%',
                }}
                variant="h2"
              >
                Subscriber Data & Filters
              </Typography>
              <Typography
                sx={{
                  width: '360px',
                  mb: '13px',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                What information do you need from the subscribing user?
              </Typography>

              {/* TODO: move to separate component */}
              <RadioGroup
                name="use-radio-group"
                defaultValue={setRadioGroupDefaultValue()}
                sx={{ flexDirection: 'row', display: 'flex' }}
                onChange={(e) => {
                  if (e.target.value === 'none') {
                    clearMessageBarState();
                    setIsUserSelectsSelect(false);
                    setUserInput('');
                    const nextState = { ...uiConfigOverride };
                    nextState.isSubscriptionValueInputable = false;
                    nextState.subscriptionValueOrRef = {
                      type: 'value',
                      value: [{ label: '', value: '*' }],
                    };
                    setUiConfigOverride(nextState);
                  } else if (e.target.value === 'wallet') {
                    clearMessageBarState();
                    setIsUserSelectsSelect(false);
                    setUserInput('');
                    const nextState = { ...uiConfigOverride };
                    nextState.isSubscriptionValueInputable = false;
                    nextState.subscriptionValueOrRef = {
                      type: 'ref',
                      ref: 'walletAddress',
                    };
                    setUiConfigOverride(nextState);
                  } else if (e.target.value === 'select') {
                    clearMessageBarState();
                    setIsUserSelectsSelect(true);
                    const nextState = { ...uiConfigOverride };
                    nextState.isSubscriptionValueInputable = true;
                    nextState.subscriptionValueOrRef = {
                      type: 'ref',
                      ref: '',
                    };
                    setUiConfigOverride(nextState);
                  }
                }}
              >
                <Stack flexDirection="column">
                  <FormControlLabel
                    value="wallet"
                    sx={{ mr: 0, height: '20px' }}
                    label={
                      <Typography
                        sx={{
                          fontWeight: 600,
                          mt: '2px',
                          fontSize: '16px',
                          fontFamily: 'Rota',
                        }}
                      >
                        Wallet Address
                      </Typography>
                    }
                    control={
                      <Radio
                        sx={{
                          '&.Mui-disabled': {
                            color: dusk,
                            opacity: 0.5,
                            cursor: 'not-allowed',
                            pointerEvents: 'unset',
                          },
                          ...checkboxCustomCSS,
                        }}
                      />
                    }
                  />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'Rota',
                      color: dusk,
                      ml: 4,
                      width: '330px',
                    }}
                  >
                    The user&apos;s wallet address is used to trigger this
                    alert. Wallet address is provided automatically with Notifi
                    React Card based on the user&apos;s connected wallet.
                  </Typography>
                </Stack>
                <Stack flexDirection="column" sx={{ mt: 2 }}>
                  <FormControlLabel
                    value="none"
                    sx={{ mr: 0, height: '20px' }}
                    label={
                      <Typography
                        sx={{
                          fontWeight: 600,
                          mt: '2px',
                          fontSize: '16px',
                          fontFamily: 'Rota',
                        }}
                      >
                        No Input From User
                      </Typography>
                    }
                    control={
                      <Radio
                        sx={{
                          '&.Mui-disabled': {
                            color: dusk,
                            opacity: 0.5,
                            cursor: 'not-allowed',
                            pointerEvents: 'unset',
                          },
                          ...checkboxCustomCSS,
                        }}
                      />
                    }
                  />
                </Stack>
                {canFilterOnTag ? (
                  <Stack flexDirection="column" sx={{ mt: 2 }}>
                    <FormControlLabel
                      value="select"
                      sx={{ mr: 0, height: '20px' }}
                      label={
                        <Typography
                          sx={{
                            fontWeight: 600,
                            mt: '2px',
                            fontSize: '16px',
                            fontFamily: 'Rota',
                          }}
                        >
                          User Selects From List
                        </Typography>
                      }
                      control={
                        <Radio
                          sx={{
                            ...checkboxCustomCSS,
                          }}
                        />
                      }
                    />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'Rota',
                        color: dusk,
                        ml: 4,
                        width: '330px',
                      }}
                    >
                      The user selects which data they want to subscribe to from
                      a list you provide.
                      <a
                        href="https://docs.notifi.network/docs/create-topics/subscription-parameters"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Docs
                      </a>
                    </Typography>
                    {isUserSelectsSelect ? (
                      <>
                        <Box
                          sx={{
                            width: '330px',
                            my: '12px',
                            ml: 4,
                            borderRadius: '4px',
                            border: `1px solid ${heather}`,
                            p: '10px',
                            fontWeight: 600,
                            fontSize: '14px',
                          }}
                        >
                          The data structure required for the list is available
                          in our docs. This will be passed into the frontend
                          card.
                        </Box>
                        <InputWithLabel
                          customLabelSx={{ ml: 4 }}
                          isEdit={isEdit}
                          value={userInput ?? ''}
                          handleChange={(e) => {
                            const nextState = { ...uiConfigOverride };
                            nextState.subscriptionValueOrRef = {
                              type: 'ref',
                              ref: e.target.value,
                            };
                            setUserInput(e.target.value);
                            setUiConfigOverride(nextState);
                          }}
                          tooltipContent="This variable name is used as a reference in the SDK."
                          label="Variable Name for Input List"
                          customSx={{ ml: 4, my: '12px', width: '330px' }}
                        />
                      </>
                    ) : null}
                  </Stack>
                ) : null}
              </RadioGroup>
            </Stack>
            <Typography
              sx={{
                width: '360px',
                mb: '13px',
                fontSize: '16px',
                fontWeight: 700,
              }}
            >
              Set a maximum frequency for users to receive alerts for this topic{' '}
              <NotifiTooltip
                tooltipContent="No matter how often this topic is raised for the user, we will ensure that the user only receives it at the specified interval time"
                buttonSx={{
                  position: 'relative',
                  marginLeft: '2px',
                }}
                tooltipSx={{ color: dusk }}
              />
            </Typography>
            {/* Frequency UI */}
            <Stack flexDirection="column">
              <InputLabel
                id="sub-user-info"
                sx={{
                  mb: 0.5,
                  fontFamily: 'Rota',
                  fontWeight: 600,
                  fontSize: '14px',
                }}
              >
                Alert Frequency
              </InputLabel>
              <Select
                labelId="sub-user-info"
                value={minutesConvertToFrequency(
                  frequency.minimumDurationBetweenTriggersInMinutes,
                )}
                onChange={(e) => {
                  const nextState = { ...frequency };
                  nextState.minimumDurationBetweenTriggersInMinutes =
                    frequencyConvertToMinutes(e.target.value);
                  setFrequency(nextState);
                  clearMessageBarState();
                }}
                IconComponent={(props) => {
                  return (
                    <IconChevronDown
                      {...props}
                      style={{
                        marginRight: '12px',
                        top: 'calc(50% - .7em)',
                        fill: isAP2Enabled ? dusk : white,
                      }}
                    />
                  );
                }}
                sx={{
                  width: '360px',
                  backgroundColor: eggshell,
                  ':before': { border: 'none' },
                  '&.MuiSelect-iconStandard': { color: 'red' },
                  mb: 3,
                }}
              >
                {ALERT_FREQUENCY_ITEMS.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {ALERT_FREQUENCY_LABELS[item]}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            {isAP2Enabled && (
              <Stack
                sx={{
                  borderTop: `2px solid ${cloud}`,
                  width: '360px',
                  pt: 3,
                  mb: 4,
                }}
              >
                {/* Optional Filters UI */}
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                  }}
                >
                  Add Optional Filters
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: dusk,
                    mt: '4px',
                    mb: '13px',
                  }}
                >
                  Let your users set parameters, like thresholds, to control
                  when they will receive this alert.
                </Typography>

                {/* Select Optional Filters UI */}
                <Stack
                  flexDirection="column"
                  justifyContent="center"
                  sx={{ width: '408px' }}
                  mb={'13px'}
                >
                  {filter.name ? (
                    <InputLabel
                      id="Select-Optional-Filter"
                      sx={{
                        mb: 0.5,
                        fontFamily: 'Rota',
                        fontWeight: 600,
                        fontSize: '14px',
                      }}
                    >
                      Select Optional Filter
                    </InputLabel>
                  ) : null}

                  <Select
                    labelId="filter-options"
                    label="Select Optional Filter"
                    value={filter.name}
                    displayEmpty
                    renderValue={
                      filter.name !== ''
                        ? undefined
                        : () => (
                            <Typography
                              sx={{
                                color: dusk,
                              }}
                            >
                              Select Optional Filter
                            </Typography>
                          )
                    }
                    onChange={(e) => {
                      handleFilterChange(e.target.value as string);
                      clearMessageBarState();
                    }}
                    IconComponent={(props) => {
                      return (
                        <IconChevronDown
                          {...props}
                          style={{
                            marginRight: '12px',
                            top: 'calc(50% - .7em)',
                            fill: isAP2Enabled ? dusk : white,
                          }}
                        />
                      );
                    }}
                    sx={{
                      width: '360px',
                      backgroundColor: eggshell,
                      ':before': { border: 'none' },
                      '&.MuiSelect-iconStandard': { color: 'red' },
                    }}
                  >
                    {FILTER_TYPE_ITEMS.map((filter, index) => (
                      <MenuItem
                        key={index}
                        value={filter}
                        sx={{ fontSize: '14px', fontWeight: 550 }}
                      >
                        {FILTER_TYPE_LABELS[filter]}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>

                {filter.name.length > 0 ? (
                  <>
                    {/* Filter Description UI */}
                    <InputLabel
                      id="Filter-description"
                      sx={{
                        mb: 0.5,
                        fontFamily: 'Rota',
                        fontWeight: 600,
                        fontSize: '14px',
                      }}
                    >
                      Filter Description
                    </InputLabel>

                    <TextField
                      sx={{
                        width: '360px',
                        mb: '13px',
                      }}
                      value={filter.description}
                      onChange={(e) => {
                        clearMessageBarState();
                        const value = (e.target as HTMLInputElement).value;
                        const nextState = { ...filter };
                        nextState.description = value;
                        nextState.userInputParams[0].description = value;
                        setFilter(nextState);
                      }}
                    />

                    {/* Threshold Type UI*/}
                    <InputLabel
                      id="threshold-type"
                      sx={{
                        mb: 0.5,
                        fontFamily: 'Rota',
                        fontWeight: 600,
                        fontSize: '14px',
                      }}
                    >
                      Threshold Type
                    </InputLabel>

                    <Select
                      labelId="threshold-type"
                      value={
                        filter.userInputParams[
                          isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
                        ].kind
                      }
                      onChange={(e) => {
                        clearMessageBarState();
                        const value = (e.target as HTMLInputElement)
                          .value as ThresholdTypeItem;
                        let nextState = { ...filter };
                        nextState = updateThresholdType(nextState, value);

                        if (!userPresetThresholdsEnabled)
                          nextState = updateThresholdType(
                            nextState,
                            'resetOptions',
                          );

                        setFilter(nextState);
                      }}
                      IconComponent={(props) => {
                        return (
                          <IconChevronDown
                            {...props}
                            style={{
                              marginRight: '12px',
                              top: 'calc(50% - .7em)',
                              fill: isAP2Enabled ? dusk : white,
                            }}
                          />
                        );
                      }}
                      sx={{
                        width: '360px',
                        backgroundColor: eggshell,
                        ':before': { border: 'none' },
                        '&.MuiSelect-iconStandard': { color: 'red' },
                      }}
                    >
                      {THRESHOLD_TYPE_ITEMS.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item}
                          sx={{ fontSize: '14px', fontWeight: 550 }}
                        >
                          {THRESHOLD_TYPE_LABELS[item]}
                        </MenuItem>
                      ))}
                    </Select>

                    {/* Checkbox UI for Custom input */}
                    <Stack>
                      <Stack flexDirection="row" alignItems="end" mt="24px">
                        <Checkbox
                          checked={allowCustomInput}
                          onChange={(e) => {
                            const isCustomInputChecked = e.target.checked;
                            let nextState = { ...filter };
                            nextState.userInputParams[
                              isUserInputParamsWithRadioTypeandCorrectOrder
                                ? 1
                                : 0
                            ].allowCustomInput = isCustomInputChecked;
                            if (
                              !(
                                'customInputConstraints' in
                                nextState.userInputParams[
                                  isUserInputParamsWithRadioTypeandCorrectOrder
                                    ? 1
                                    : 0
                                ]
                              )
                            ) {
                              nextState.userInputParams[
                                isUserInputParamsWithRadioTypeandCorrectOrder
                                  ? 1
                                  : 0
                              ].customInputConstraints = {
                                lowerBound: undefined,
                                upperBound: undefined,
                                maxDecimalPlaces: undefined,
                              };
                            }

                            if (
                              !isCustomInputChecked &&
                              !userPresetThresholdsEnabled
                            ) {
                              nextState = updateThresholdType(nextState);
                              setUserPresetThresholdsEnabled(true);
                            }

                            setFilter({ ...nextState });
                          }}
                          sx={{
                            mr: '10px',
                            p: 0,
                            color: dusk,
                            '&.Mui-checked': {
                              color: blueglass,
                            },
                          }}
                        />
                        <Typography
                          sx={{ fontSize: '16px', fontWeight: '700' }}
                        >
                          Allow user to input a custom value
                        </Typography>
                      </Stack>
                      {allowCustomInput ? (
                        <>
                          <Stack flexDirection="row" gap={2} sx={{ mt: 3.5 }}>
                            <InputWithLabel
                              type="number"
                              value={Number(
                                filter.userInputParams[
                                  isUserInputParamsWithRadioTypeandCorrectOrder
                                    ? 1
                                    : 0
                                ]?.customInputConstraints?.lowerBound ??
                                  undefined,
                              )}
                              handleChange={(e) => {
                                const value = e.target.value;
                                const nextState = { ...filter };
                                if (
                                  nextState?.userInputParams[1]
                                    ?.customInputConstraints
                                ) {
                                  nextState.userInputParams[1].customInputConstraints.lowerBound =
                                    Number(value);
                                } else {
                                  if (
                                    nextState?.userInputParams[0]
                                      ?.customInputConstraints
                                  ) {
                                    nextState.userInputParams[0].customInputConstraints.lowerBound =
                                      Number(value);
                                  } else {
                                    nextState.userInputParams[
                                      isUserInputParamsWithRadioTypeandCorrectOrder
                                        ? 1
                                        : 0
                                    ].customInputConstraints = {
                                      lowerBound: Number(value),
                                      upperBound: undefined,
                                      maxDecimalPlaces: undefined,
                                    };
                                  }
                                }
                                setFilter(nextState);
                              }}
                              label="Lower limit"
                              customSx={{
                                width: '170px',
                              }}
                            />
                            <InputWithLabel
                              type="number"
                              value={Number(
                                filter.userInputParams[
                                  isUserInputParamsWithRadioTypeandCorrectOrder
                                    ? 1
                                    : 0
                                ]?.customInputConstraints?.upperBound ??
                                  undefined,
                              )}
                              handleChange={(e) => {
                                const value = e.target.value;
                                const nextState = { ...filter };
                                if (
                                  nextState.userInputParams[1]
                                    ?.customInputConstraints
                                ) {
                                  nextState.userInputParams[1].customInputConstraints.upperBound =
                                    Number(value);
                                } else {
                                  if (
                                    nextState?.userInputParams[0]
                                      ?.customInputConstraints
                                  ) {
                                    nextState.userInputParams[0].customInputConstraints.upperBound =
                                      Number(value);
                                  } else {
                                    nextState.userInputParams[
                                      isUserInputParamsWithRadioTypeandCorrectOrder
                                        ? 1
                                        : 0
                                    ].customInputConstraints = {
                                      lowerBound: undefined,
                                      upperBound: Number(value),
                                      maxDecimalPlaces: undefined,
                                    };
                                  }
                                }
                                setFilter(nextState);
                              }}
                              label="Upper limit"
                              customSx={{ width: '170px' }}
                            />
                          </Stack>
                          <InputWithLabel
                            type="number"
                            value={Number(
                              filter.userInputParams[
                                isUserInputParamsWithRadioTypeandCorrectOrder
                                  ? 1
                                  : 0
                              ]?.customInputConstraints?.maxDecimalPlaces ??
                                undefined,
                            )}
                            handleChange={(e) => {
                              clearMessageBarState();
                              const value = e.target.value;
                              const nextState = { ...filter };
                              if (!/^\d+$/.test(value)) {
                                return;
                              }
                              if (
                                nextState.userInputParams[1]
                                  ?.customInputConstraints
                              ) {
                                nextState.userInputParams[1].customInputConstraints.maxDecimalPlaces =
                                  Number(value);
                              } else {
                                if (
                                  nextState?.userInputParams[0]
                                    ?.customInputConstraints
                                ) {
                                  nextState.userInputParams[0].customInputConstraints.maxDecimalPlaces =
                                    Number(value);
                                } else {
                                  nextState.userInputParams[
                                    isUserInputParamsWithRadioTypeandCorrectOrder
                                      ? 1
                                      : 0
                                  ].customInputConstraints = {
                                    lowerBound: undefined,
                                    upperBound: undefined,
                                    maxDecimalPlaces: Number(value),
                                  };
                                }
                              }
                              setFilter(nextState);
                            }}
                            label="Set a decimal limit (optional)"
                            customSx={{ mt: 3.5 }}
                          />
                        </>
                      ) : null}
                    </Stack>

                    {/* Checkbox UI for Thresholds Inputs*/}
                    <Stack flexDirection="row" alignItems="end" mt="24px">
                      <Checkbox
                        checked={userPresetThresholdsEnabled}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          let nextState = { ...filter };

                          if (!checked) {
                            nextState = updateThresholdType(
                              nextState,
                              'resetOptions',
                            );
                          } else {
                            nextState = updateThresholdType(nextState);
                          }

                          if (
                            !checked &&
                            !nextState.userInputParams[
                              isUserInputParamsWithRadioTypeandCorrectOrder
                                ? 1
                                : 0
                            ].allowCustomInput
                          ) {
                            nextState.userInputParams[
                              isUserInputParamsWithRadioTypeandCorrectOrder
                                ? 1
                                : 0
                            ].allowCustomInput = true;
                          }

                          setFilter({ ...nextState });
                          setUserPresetThresholdsEnabled(checked);
                        }}
                        sx={{
                          mr: '10px',
                          p: 0,
                          color: dusk,
                          '&.Mui-checked': {
                            color: blueglass,
                          },
                        }}
                      />
                      <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>
                        Give user preset thresholds
                      </Typography>
                    </Stack>

                    {/* Threshold Options Input UI */}
                    {userPresetThresholdsEnabled ? (
                      <Stack flexDirection="row" gap={2} mt={1.75}>
                        {/* Threshold Option 2 UI */}
                        <Stack>
                          <InputLabel
                            sx={{
                              mb: 0.5,
                              fontFamily: 'Rota',
                              fontWeight: 600,
                              fontSize: '14px',
                            }}
                          >
                            Threshold Option 1
                          </InputLabel>

                          <TextField
                            sx={{
                              width: '170px',
                            }}
                            type="number"
                            value={Number(
                              filter.userInputParams[
                                isUserInputParamsWithRadioTypeandCorrectOrder
                                  ? 1
                                  : 0
                              ].options[0],
                            )}
                            inputProps={{
                              step:
                                filter.userInputParams[
                                  isUserInputParamsWithRadioTypeandCorrectOrder
                                    ? 1
                                    : 0
                                ].kind === 'integer'
                                  ? '1'
                                  : '.1',
                            }}
                            onChange={(e) => {
                              const numValue = Number(e.target.value);
                              const strValue = String(numValue);
                              const nextState = { ...filter };
                              const thresholdType =
                                nextState.userInputParams[
                                  isUserInputParamsWithRadioTypeandCorrectOrder
                                    ? 1
                                    : 0
                                ].kind;

                              if (numValue < 0) return;

                              if (
                                (thresholdType === 'integer' &&
                                  /^[0-9]\d*$/.test(strValue) &&
                                  strValue.length < 6) ||
                                (thresholdType === 'float' &&
                                  /^[0-9]{0,5}(\.[0-9]{0,3})?$/.test(strValue))
                              ) {
                                clearMessageBarState();
                                nextState.userInputParams[
                                  isUserInputParamsWithRadioTypeandCorrectOrder
                                    ? 1
                                    : 0
                                ].options[0] = numValue > 0 ? numValue : '';
                                nextState.userInputParams[
                                  isUserInputParamsWithRadioTypeandCorrectOrder
                                    ? 1
                                    : 0
                                ].defaultValue = numValue > 0 ? numValue : '';
                                setFilter(nextState);
                              }
                            }}
                          />
                        </Stack>

                        {/* Threshold Option 2 UI */}
                        <Stack>
                          <InputLabel
                            sx={{
                              mb: 0.5,
                              fontFamily: 'Rota',
                              fontWeight: 600,
                              fontSize: '14px',
                            }}
                          >
                            Threshold Option 2
                          </InputLabel>

                          <TextField
                            sx={{
                              width: '170px',
                            }}
                            type="number"
                            value={Number(
                              filter.userInputParams[
                                isUserInputParamsWithRadioTypeandCorrectOrder
                                  ? 1
                                  : 0
                              ].options[1],
                            )}
                            inputProps={{
                              step:
                                filter.userInputParams[
                                  isUserInputParamsWithRadioTypeandCorrectOrder
                                    ? 1
                                    : 0
                                ].kind === 'integer'
                                  ? '1'
                                  : '.1',
                            }}
                            onChange={(e) => {
                              const numValue = Number(e.target.value);
                              const strValue = String(numValue);
                              const nextState = { ...filter };
                              const thresholdType =
                                nextState.userInputParams[
                                  isUserInputParamsWithRadioTypeandCorrectOrder
                                    ? 1
                                    : 0
                                ].kind;

                              if (numValue < 0) return;

                              if (
                                (thresholdType === 'integer' &&
                                  /^[0-9]\d*$/.test(strValue) &&
                                  strValue.length < 6) ||
                                (thresholdType === 'float' &&
                                  /^[0-9]{0,5}(\.[0-9]{0,3})?$/.test(strValue))
                              ) {
                                clearMessageBarState();
                                nextState.userInputParams[
                                  isUserInputParamsWithRadioTypeandCorrectOrder
                                    ? 1
                                    : 0
                                ].options[1] = numValue > 0 ? numValue : '';
                                setFilter(nextState);
                              }
                            }}
                          />
                        </Stack>
                      </Stack>
                    ) : null}

                    {/* Customize threshold radio option UI */}
                    <Stack alignItems="baseline" mt={'24px'}>
                      <InputLabel
                        sx={{
                          textAlign: 'left',
                          fontSize: '14px',
                          color: dusk,
                          fontFamily: 'Rota',
                          fontWeight: 600,
                          mr: '4px',
                        }}
                      >
                        Customize threshold
                      </InputLabel>

                      <Stack
                        flexDirection="row"
                        width={'100%'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginTop="6px"
                      >
                        <FormControlLabel
                          sx={{ mr: 0 }}
                          label={
                            <Typography
                              sx={{
                                fontWeight: 600,
                                mt: '2px',
                                fontSize: '16px',
                                fontFamily: 'Rota',
                              }}
                            >
                              None
                            </Typography>
                          }
                          control={
                            <Radio
                              checked={!customizedThresholdType}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  const nextState = { ...filter };
                                  nextState.userInputParams[
                                    isUserInputParamsWithRadioTypeandCorrectOrder
                                      ? 1
                                      : 0
                                  ].prefixAndSuffix = {
                                    prefix: '',
                                    suffix: '',
                                  };
                                  setCustomizedThresholdType(undefined);
                                  setFilter(nextState);
                                }
                              }}
                              sx={{
                                ...checkboxCustomCSS,
                              }}
                            />
                          }
                        />

                        <FormControlLabel
                          sx={{ mx: 0 }}
                          label={
                            <Typography
                              sx={{
                                fontWeight: 600,
                                mt: '2px',
                                fontSize: '16px',
                                fontFamily: 'Rota',
                              }}
                            >
                              Prefix
                            </Typography>
                          }
                          control={
                            <Radio
                              checked={customizedThresholdType === 'Prefix'}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  const nextState = { ...filter };
                                  nextState.userInputParams[
                                    isUserInputParamsWithRadioTypeandCorrectOrder
                                      ? 1
                                      : 0
                                  ].prefixAndSuffix = {
                                    prefix: prefixAndSuffixValue,
                                    suffix: '',
                                  };
                                  setFilter({ ...nextState });
                                  setCustomizedThresholdType('Prefix');
                                }
                              }}
                              sx={{
                                ...checkboxCustomCSS,
                              }}
                            />
                          }
                        />

                        <FormControlLabel
                          sx={{ mx: 0 }}
                          label={
                            <Typography
                              sx={{
                                fontWeight: 600,
                                mt: '2px',
                                fontSize: '16px',
                                fontFamily: 'Rota',
                              }}
                            >
                              Suffix
                            </Typography>
                          }
                          control={
                            <Radio
                              checked={customizedThresholdType === 'Suffix'}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  const nextState = { ...filter };
                                  nextState.userInputParams[
                                    isUserInputParamsWithRadioTypeandCorrectOrder
                                      ? 1
                                      : 0
                                  ].prefixAndSuffix = {
                                    prefix: '',
                                    suffix: prefixAndSuffixValue,
                                  };
                                  setFilter({ ...nextState });
                                  setCustomizedThresholdType('Suffix');
                                }
                              }}
                              sx={{
                                ...checkboxCustomCSS,
                              }}
                            />
                          }
                        />
                      </Stack>
                    </Stack>

                    {/* Customize threshold input UI */}
                    {customizedThresholdType ? (
                      <>
                        <InputLabel
                          id="Filter-description"
                          sx={{
                            mb: 0.5,
                            fontFamily: 'Rota',
                            fontWeight: 600,
                            fontSize: '14px',
                            mt: 1.5,
                          }}
                        >
                          Add {customizedThresholdType}
                        </InputLabel>

                        <TextField
                          sx={{
                            width: '360px',
                          }}
                          value={prefixAndSuffixValue}
                          onChange={(e) => {
                            clearMessageBarState();
                            const value = e.target.value;
                            if (value.length > 4) return;

                            const nextState = { ...filter };
                            nextState.userInputParams[
                              isUserInputParamsWithRadioTypeandCorrectOrder
                                ? 1
                                : 0
                            ].prefixAndSuffix = {
                              prefix:
                                customizedThresholdType === 'Prefix'
                                  ? value
                                  : '',
                              suffix:
                                customizedThresholdType === 'Suffix'
                                  ? value
                                  : '',
                            };

                            setFilter(nextState);
                          }}
                        />
                      </>
                    ) : null}

                    <Stack flexDirection="row" alignItems="baseline" mt={3}>
                      <InputLabel
                        sx={{
                          textAlign: 'left',
                          fontSize: '14px',
                          color: dusk,
                          fontFamily: 'Rota',
                          fontWeight: 600,
                          mr: '4px',
                        }}
                      >
                        Variable Name Input
                      </InputLabel>
                      <NotifiTooltip
                        tooltipContent="When you write your parser, the value used in this filter should be provided in this name."
                        boxSx={{
                          height: '100px',
                          color: midnight,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          pl: '20px',
                          borderRadius: '8px',
                          boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
                        }}
                      />
                    </Stack>
                    <TextField
                      disabled={true}
                      sx={{
                        width: '360px',
                        mb: 4,
                        '& .MuiInputBase-input.Mui-disabled': {
                          WebkitTextFillColor: midnight,
                        },
                      }}
                      value={showVariableNameValue()}
                    />
                  </>
                ) : null}
              </Stack>
            )}
          </Stack>
        </Stack>

        {/* Filter Preview Card UI */}
        <Box sx={{ width: '367px', position: 'relative' }}>
          <FilterPreviewCard
            allowCustomInput={
              filter.userInputParams[
                isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
              ].allowCustomInput
            }
            name={
              uiConfigOverride.topicDisplayName
                ? uiConfigOverride.topicDisplayName
                : topicName
            }
            topicDisplayName={uiConfigOverride.topicDisplayName}
            thresholdDirection={filter.name}
            filterDescription={filter.description}
            thresholdValues={filter.userInputParams[
              isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
            ].options.map<string>((v) => v.toString())}
            thresholdType={
              filter.userInputParams[
                isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
              ].kind
            }
            prefixAndSuffixValue={prefixAndSuffixValue}
            customizedThresholdType={customizedThresholdType}
          />
        </Box>
      </Stack>
    </div>
  );
};

export default CreateNonCMTopicStep1Card;
