import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  Observable,
  NextLink,
  Operation,
  FetchResult,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import React, { PropsWithChildren, useMemo, useRef } from 'react';
import { getApiUrl } from './envUtils';
import { useNotifiEnv } from './NotifiEnvContext';
import { FeatureFlagProvider } from './FeatureFlagContext';
import { useAuthContext } from './AuthContext';
import { SubscriptionObserver } from 'zen-observable-ts';
import FlagChecker from './FlagChecker';

type PendingRequest = {
  operation: Operation;
  forward: NextLink;
  observer: SubscriptionObserver<FetchResult>;
};

type Obj = { [key: string]: any };

function findErrorsKey(obj: Obj): any[] | null {
  if (typeof obj === 'object' && obj !== null) {
    if (Object.prototype.hasOwnProperty.call(obj, 'errors')) {
      return obj.errors;
    }
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const result = findErrorsKey(obj[key]);
        if (result) {
          return result;
        }
      }
    }
  }
  return null;
}

type DataType =
  | string
  | number
  | boolean
  | null
  | undefined
  | DataType[]
  | { [key: string]: DataType };

function containsErrorAuthCode(obj: DataType, target: string) {
  function search(value: DataType): boolean {
    if (typeof value === 'string' && value === target) {
      return true;
    }
    if (Array.isArray(value)) {
      return value.some((item) => search(item));
    }
    if (typeof value === 'object' && value !== null) {
      return Object.values(value).some((item) => search(item));
    }
    return false;
  }
  return search(obj);
}

export const LoggedIn: React.FC<
  PropsWithChildren<Readonly<{ normalPermissionToken: string | null }>>
> = ({ children }) => {
  const { notifiEnv } = useNotifiEnv();
  const authContext = useAuthContext();
  const tokenKey = useMemo(() => `config-tool:${notifiEnv}:token`, [notifiEnv]);
  const refreshTokenKey = useMemo(
    () => `config-tool:${notifiEnv}:refreshToken`,
    [notifiEnv],
  );
  const normalPermissionTokenKey = useMemo(
    () => `config-tool:${notifiEnv}:normalPermissionToken`,
    [notifiEnv],
  );
  const normalPermissionsTokenExpiryKey = useMemo(
    () => `${normalPermissionTokenKey}:expiry`,
    [normalPermissionTokenKey],
  );
  const elevatedPermissionsTokenKey = useMemo(
    () => `${notifiEnv}:elevatedPermissionsToken`,
    [notifiEnv],
  );
  const elevatedPermissionsTokenExpiryKey = useMemo(
    () => `${elevatedPermissionsTokenKey}:expiry`,
    [elevatedPermissionsTokenKey],
  );
  const httpLink = new HttpLink({ uri: getApiUrl(notifiEnv) });
  const isTokenRefreshing = useRef(false);

  const authLink = new ApolloLink((operation, forward) => {
    let existingNormalPermissionToken = localStorage.getItem(
      normalPermissionTokenKey,
    );
    return new Observable((observer) => {
      const headers = {
        ...operation.getContext().headers,
        Authorization: `Bearer ${existingNormalPermissionToken}`,
      };
      operation.setContext({ headers });
      forward(operation).subscribe({
        next: async (response) => {
          const error = findErrorsKey(response);
          if (
            error &&
            (containsErrorAuthCode(error, 'AUTH_NOT_AUTHORIZED') ||
              containsErrorAuthCode(error, 'AUTH_NOT_AUTHENTICATED'))
          ) {
            let currentrRefreshToken = localStorage.getItem(refreshTokenKey);
            const oldToken = localStorage.getItem(tokenKey);
            if (oldToken) {
              if (!currentrRefreshToken) {
                currentrRefreshToken = oldToken;
              }
            }
            try {
              const {
                normalPermissionToken,
                refreshToken,
                normalPermissionsTokenExpiry,
              } = await authContext.generateNormalPermissionsToken(
                currentrRefreshToken!,
              );
              localStorage.setItem(refreshTokenKey, refreshToken);
              localStorage.setItem(
                normalPermissionTokenKey,
                normalPermissionToken,
              );
              localStorage.setItem(
                normalPermissionsTokenExpiryKey,
                normalPermissionsTokenExpiry,
              );
              existingNormalPermissionToken = normalPermissionToken;
              localStorage.removeItem(tokenKey);
              operation.setContext({
                headers: {
                  ...operation.getContext().headers,
                  Authorization: `Bearer ${normalPermissionToken}`,
                },
              });
              forward(operation).subscribe(observer);
            } catch (refreshError) {
              observer.error(refreshError);
              if (authContext.type === 'loggedIn') {
                authContext.logOut();
              }
            }
          } else if (
            error &&
            containsErrorAuthCode(
              error,
              'ERROR_AUTH_REQUIRES_ELEVATED_TOKEN',
            ) &&
            authContext.type === 'loggedIn'
          ) {
            try {
              let existingElevatedPermissionsToken = localStorage.getItem(
                elevatedPermissionsTokenKey,
              );
              if (!authContext.reuseElevatedTokenRef.current) {
                const {
                  elevatedPermissionsToken,
                  elevatedPermissionsTokenExpiry,
                } =
                  await authContext.generateElevatedPermissionsAuthorizationToken(
                    existingNormalPermissionToken!,
                  );
                localStorage.setItem(
                  elevatedPermissionsTokenKey,
                  elevatedPermissionsToken,
                );
                localStorage.setItem(
                  elevatedPermissionsTokenExpiryKey,
                  elevatedPermissionsTokenExpiry,
                );
                existingElevatedPermissionsToken = elevatedPermissionsToken;
              }

              operation.setContext({
                headers: {
                  ...operation.getContext().headers,
                  Authorization: `Bearer ${existingElevatedPermissionsToken}`,
                },
              });
              forward(operation).subscribe(observer);
            } catch (elevatedPermissionsError: any) {
              observer.error(elevatedPermissionsError);
              observer.complete();
            }
          } else {
            observer.next(response);
            observer.complete();
          }
        },
        error: (err) => {
          observer.error(err);
        },
      });
    });
  });

  const pendingRequests: PendingRequest[] = [];

  const processPendingRequests = () => {
    while (pendingRequests.length > 0) {
      const { operation, forward, observer } = pendingRequests.shift()!;
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem(
            normalPermissionTokenKey,
          )}`,
        },
      }));
      forward(operation).subscribe({
        next: (response) => observer.next(response),
        error: (error) => observer.error(error),
        complete: () => observer.complete(),
      });
    }
  };

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (
            err.extensions?.code === 'AUTH_NOT_AUTHORIZED' ||
            err.extensions?.code === 'AUTH_NOT_AUTHENTICATED'
          ) {
            let refreshToken = localStorage.getItem(refreshTokenKey);
            const oldToken = localStorage.getItem(tokenKey);
            if (oldToken) {
              if (!refreshToken) {
                refreshToken = oldToken;
              }
            }
            if (refreshToken) {
              return new Observable((observer) => {
                const retryWithNormalPermissionsToken = () => {
                  isTokenRefreshing.current = true; // This is lock implementation for restricting concurrent onError from updating refresh token
                  authContext
                    .generateNormalPermissionsToken(refreshToken!)
                    .then(
                      ({
                        normalPermissionToken,
                        refreshToken,
                        normalPermissionsTokenExpiry,
                      }) => {
                        localStorage.setItem(refreshTokenKey, refreshToken);
                        localStorage.setItem(
                          normalPermissionTokenKey,
                          normalPermissionToken,
                        );
                        localStorage.setItem(
                          normalPermissionsTokenExpiryKey,
                          normalPermissionsTokenExpiry,
                        );
                        operation.setContext(({ headers = {} }) => ({
                          headers: {
                            ...headers,
                            Authorization: `Bearer ${normalPermissionToken}`,
                          },
                        }));
                        forward(operation).subscribe({
                          next: (response) => observer.next(response),
                          error: (error) => observer.error(error),
                          complete: () => observer.complete(),
                        });

                        if (authContext.type === 'loggedIn') {
                          authContext.setNormalPermissionToken(
                            normalPermissionToken,
                          );
                        }
                        localStorage.removeItem(tokenKey);
                        isTokenRefreshing.current = false;

                        // Process pending requests
                        processPendingRequests();
                      },
                    )
                    .catch((error) => {
                      if (authContext.type === 'loggedIn') {
                        authContext.logOut();
                      }
                      observer.error(error);
                      processPendingRequests();
                    });
                };

                if (isTokenRefreshing.current) {
                  pendingRequests.push({ operation, forward, observer });
                } else {
                  retryWithNormalPermissionsToken();
                }
              });
            }
          }
        }
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    },
  );

  const link = ApolloLink.from([authLink, errorLink, httpLink]);

  const apolloClient = useMemo(() => {
    return new ApolloClient({
      link,
      cache: new InMemoryCache(),
    });
  }, [notifiEnv]);

  return (
    <ApolloProvider client={apolloClient}>
      <FeatureFlagProvider>
        <FlagChecker>{children}</FlagChecker>
      </FeatureFlagProvider>
    </ApolloProvider>
  );
};
