import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  CreateFusionSourceInput,
  FusionSourceWithCursor,
  WalletBlockchain,
} from 'src/services/gql/generated';
import { useTopicsDataContext } from './TopicsDataContext';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';

export const createNewParser = 'Create New Parser' as const;
export const createNewParserDefault = {
  name: createNewParser,
  id: createNewParser,
} as FusionSourceWithCursor;

type fusionSourceDataType = Pick<
  CreateFusionSourceInput,
  'blockchainType' | 'filter' | 'name'
>;

type OnOffChainFormContextState = Readonly<{
  fusionEventData: {
    name: string;
    isCommunityManagerEvent?: boolean;
    metaDataJson?: string;
  };
  loading: boolean;
  isSaveTopicButtonEnabled: boolean;
  parserName: string;
  selectedParser: FusionSourceWithCursor;
  setIsSaveTopicButtonEnabled: (value: boolean) => void;
  setParserName: (value: string) => void;
  setSelectedParser: (value: FusionSourceWithCursor) => void;
  setFusionEventData: (
    value: OnOffChainFormContextState['fusionEventData'],
  ) => void;
  setFusionSourceData: (value: fusionSourceDataType) => void;
  handleSaveTopicData: () => Promise<boolean | undefined>;
  selectedBlockchain: WalletBlockchain;
  setSelectedBlockchain: Dispatch<SetStateAction<WalletBlockchain>>;
}>;

const OnOffChainFormContext = createContext<OnOffChainFormContextState>({
  loading: false,
  isSaveTopicButtonEnabled: false,
  fusionEventData: { name: '', metaDataJson: '' },
  parserName: '',
  selectedParser: createNewParserDefault,
  setParserName: () => null,
  setSelectedParser: () => null,
  setFusionEventData: () => null,
  setIsSaveTopicButtonEnabled: () => null,
  setFusionSourceData: () => null,
  handleSaveTopicData: () => Promise.reject('Uninitialized'),
  selectedBlockchain: WalletBlockchain.ETHEREUM,
  setSelectedBlockchain: () => null,
});

export const OnOffChainFormContextProvider: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  //
  const [fusionEventData, setFusionEventData] = useState<
    OnOffChainFormContextState['fusionEventData']
  >({ name: '', metaDataJson: '' });
  const [fusionSourceData, setFusionSourceData] =
    useState<fusionSourceDataType>({
      blockchainType: WalletBlockchain.ETHEREUM,
      filter: '',
      name: '',
    });
  const [selectedParser, setSelectedParser] = useState<FusionSourceWithCursor>(
    createNewParserDefault,
  );
  const [selectedBlockchain, setSelectedBlockchain] = useState(
    WalletBlockchain.ETHEREUM,
  );
  const [parserName, setParserName] = useState<string>('');
  const [isSaveTopicButtonEnabled, setIsSaveTopicButtonEnabled] =
    useState<boolean>(false);

  const {
    create: createFusionEvent,
    createFusionSource,
    updateFusionSource,
    addFusionEventToFusionSource,
    loading: createLoading,
  } = useTopicsDataContext();

  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();

  const setErrorMessage = useCallback(
    (value: string) => {
      setMessageBarState({ status: 'error', message: value });
    },
    [setMessageBarState],
  );

  const loading = createLoading;

  const handleSaveTopicData = useCallback(async () => {
    clearMessageBarState();
    try {
      const { id } = await createFusionEvent(
        fusionEventData.name,
        undefined,
        fusionEventData?.metaDataJson,
        false,
      );
      if (selectedParser.id !== createNewParser) {
        await updateFusionSource({
          fusionSourceId: selectedParser.id,
          filter: fusionSourceData.filter,
        });

        await addFusionEventToFusionSource({
          fusionEventId: id,
          fusionSourceId: selectedParser.id,
        });
      } else {
        await createFusionSource({
          ...fusionSourceData,
          fusionEventTypeIds: [id],
        });
      }

      setParserName('');
      return true;
    } catch (err) {
      setErrorMessage((err as Error).message);
      return;
    }
  }, [
    addFusionEventToFusionSource,
    clearMessageBarState,
    createFusionEvent,
    createFusionSource,
    fusionEventData,
    fusionSourceData,
    selectedParser.id,
    setErrorMessage,
    updateFusionSource,
  ]);

  return (
    <OnOffChainFormContext.Provider
      value={{
        fusionEventData,
        setFusionEventData,
        loading,
        parserName,
        setParserName,
        selectedParser,
        setSelectedParser,
        isSaveTopicButtonEnabled,
        setIsSaveTopicButtonEnabled,
        setFusionSourceData,
        handleSaveTopicData,
        selectedBlockchain,
        setSelectedBlockchain,
      }}
    >
      {children}
    </OnOffChainFormContext.Provider>
  );
};

export const useOnOffChainFormContext = (): OnOffChainFormContextState => {
  return useContext(OnOffChainFormContext);
};
