import * as React from 'react';
import {
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import {
  dusk,
  eggshell,
  midnight,
  blueglass,
  white,
} from 'src/constants/colors';
import { useState } from 'react';
import { ReactComponent as IconChevronDown } from '../../assets/icn-chevron-down.svg';
import CreateOnChainTopicStep2 from './CreateOnChainTopicStep2';
import CreateOffChainTopicStep2 from './CreateOffChainTopicStep2';
import { useExistingParserData } from './TopicsDataContext';
import { WalletBlockchain } from 'src/services/gql/generated';
import {
  createNewParser,
  createNewParserDefault,
  useOnOffChainFormContext,
} from './OnOffChainFormContext';
import { checkboxCustomCSS } from '@components/cardConfig/common/CheckboxCSS';
import ArbitrumIcon from '../../assets/icn-arbitrum.svg';
import BaseIcon from '../../assets/icn-base.svg';
import bnbIcon from '../../assets/icn-bnb.svg';
import evmosIcon from '../../assets/icn-evmos.svg';
import polygonIcon from '../../assets/icn-polygon.svg';
import SolanaIcon from '../../assets/chains/icn-sol.png';
import SuiIcon from '../../assets/chains/icn-sui.png';
import ethIcon from '../../assets/icn-eth.svg';
import avaxIcon from '../../assets/icn-avax.svg';
import zksyncIcon from '../../assets/chains/icn-zksync.png';
import OptimismIcon from '../../assets/image 203.svg';
import { FilterPreviewCard } from './FilterPreviewCard';
import { AlertFilter, FusionEventMetadata } from './CreateNonCMTopicDialog';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const availableBlockchain: {
  icon: string;
  value: WalletBlockchain;
  label: string;
}[] = [
  { icon: ArbitrumIcon, label: 'Arbitrum', value: WalletBlockchain.ARBITRUM },
  { icon: avaxIcon, label: 'Avalanche', value: WalletBlockchain.AVALANCHE },
  { icon: BaseIcon, label: 'Base', value: WalletBlockchain.BASE },
  { icon: bnbIcon, label: 'BNB', value: WalletBlockchain.BINANCE },
  { icon: ethIcon, label: 'Ethereum', value: WalletBlockchain.ETHEREUM },
  { icon: evmosIcon, label: 'EVMos', value: WalletBlockchain.EVMOS },
  { icon: OptimismIcon, label: 'Optimism', value: WalletBlockchain.OPTIMISM },
  { icon: polygonIcon, label: 'Polygon', value: WalletBlockchain.POLYGON },
  { icon: SolanaIcon, label: 'Solana', value: WalletBlockchain.SOLANA },
  { icon: SuiIcon, label: 'Sui', value: WalletBlockchain.SUI },
  { icon: zksyncIcon, label: 'zkSync', value: WalletBlockchain.ZKSYNC },
];

type Props = {
  topicName: string;
  filter: AlertFilter;
  uiConfigOverride: FusionEventMetadata['uiConfigOverride'];
};

const OnOffChainTopicLayout: React.FC<Props> = ({
  filter,
  uiConfigOverride,
  topicName,
}) => {
  const isUserInputParamsWithRadioTypeandCorrectOrder =
    filter?.userInputParams[0]?.uiType === 'radio';
  const prefixValue =
    filter.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].prefixAndSuffix?.prefix;
  const suffixValue =
    filter.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].prefixAndSuffix?.suffix;
  const prefixAndSuffixValue = prefixValue || suffixValue || '';
  const [customizedThresholdType] = React.useState<
    'Prefix' | 'Suffix' | undefined
  >(prefixValue ? 'Prefix' : suffixValue ? 'Suffix' : undefined);

  const [alertType, setAlertType] = useState<'On-chain' | 'Off-chain'>(
    'On-chain',
  );

  const {
    selectedParser,
    setSelectedParser,
    parserName,
    setParserName,
    selectedBlockchain,
    setSelectedBlockchain,
  } = useOnOffChainFormContext();
  const { parserData } = useExistingParserData();

  const existingParser = React.useMemo(() => {
    const isOffChain = alertType === 'Off-chain';

    return parserData.filter(
      (parser) =>
        parser.cursor &&
        (isOffChain
          ? parser.blockchainSource === WalletBlockchain.OFF_CHAIN
          : parser.blockchainSource === selectedBlockchain),
    );
  }, [alertType, parserData, selectedBlockchain]);

  const handleAlertTypeChange = (value: typeof alertType) => {
    setAlertType(value);
  };

  const handleParserChange = (parserId: string) => {
    const parser = existingParser.find(({ id }) => id === parserId);
    if (parser) {
      setSelectedBlockchain(parser.blockchainSource);
      setSelectedParser(parser);
    } else {
      setSelectedParser(createNewParserDefault);
    }
  };

  React.useEffect(() => {
    setSelectedParser(createNewParserDefault);
  }, [alertType, setSelectedParser]);

  const handleBlockchainChange = (value: WalletBlockchain) => {
    setSelectedBlockchain(value);
    setSelectedParser(createNewParserDefault);
  };

  return (
    <Stack flexDirection="row" gap={2}>
      <Box sx={{ width: '367px', position: 'relative' }}>
        <Box
          sx={{
            position: 'fixed',
            backgroundColor: '#FEFEFE',
            width: '367px',
            borderRadius: '8px',
            p: 4,
          }}
        >
          <Stack direction={'row'} gap={2}>
            <Typography variant="body2" color={midnight} fontWeight={700}>
              Need some guidance?
            </Typography>
            <Stack
              sx={{ display: 'flex', flexDirection: 'row' }}
              onClick={() =>
                window.open(
                  'https://docs.notifi.network/docs/create-topics/notifi-monitored',
                  '_blank',
                )
              }
            >
              <Typography
                sx={{
                  color: blueglass,
                  fontSize: '14px',
                  fontWeight: 700,
                  p: 0,
                  cursor: 'pointer',
                }}
              >
                Docs
              </Typography>
              <OpenInNewIcon
                sx={{
                  color: blueglass,
                  fontSize: '16px',
                  p: 0,
                  cursor: 'pointer',
                  marginLeft: '6px',
                  fontWeight: 'bold',
                }}
              />
            </Stack>
          </Stack>
          <Stack direction={'row'} alignItems={'center'}></Stack>
        </Box>
      </Box>
      <Stack position={'relative'} mb={3}>
        <Stack
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: white,
            borderRadius: '8px',
            p: 4,
          }}
        >
          <Stack width={'360px'} mb={4}>
            {/* Alert Type */}
            <Stack mb={1.5}>
              <InputLabel
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: dusk,
                  textAlign: 'left',
                }}
              >
                Is this alert on or off chain?
              </InputLabel>

              <Stack flexDirection="row" marginTop="3px" gap="50px">
                <FormControlLabel
                  sx={{ mr: 0 }}
                  label={
                    <Typography
                      sx={{
                        fontWeight: 600,
                        mt: '2px',
                        fontSize: '16px',
                        fontFamily: 'Rota',
                      }}
                    >
                      On-chain
                    </Typography>
                  }
                  control={
                    <Radio
                      checked={alertType === 'On-chain'}
                      onChange={(e) => {
                        if (e.target.checked) handleAlertTypeChange('On-chain');
                      }}
                      sx={{
                        ...checkboxCustomCSS,
                      }}
                    />
                  }
                />

                <FormControlLabel
                  sx={{ mx: 0 }}
                  label={
                    <Typography
                      sx={{
                        fontWeight: 600,
                        mt: '2px',
                        fontSize: '16px',
                        fontFamily: 'Rota',
                      }}
                    >
                      Off-chain
                    </Typography>
                  }
                  control={
                    <Radio
                      checked={alertType === 'Off-chain'}
                      onChange={(e) => {
                        if (e.target.checked)
                          handleAlertTypeChange('Off-chain');
                      }}
                      sx={{
                        ...checkboxCustomCSS,
                      }}
                    />
                  }
                />
              </Stack>
            </Stack>

            {alertType === 'On-chain' && (
              <Stack flexDirection="column" mb={1.5}>
                <InputLabel
                  id="blockchain-info"
                  sx={{
                    mb: 0.125,
                    fontFamily: 'Rota',
                    fontWeight: 700,
                    fontSize: '14px',
                  }}
                >
                  Select blockchain
                </InputLabel>

                <Select
                  labelId="blockchain-info"
                  value={selectedBlockchain}
                  onChange={(e) => {
                    handleBlockchainChange(e.target.value as WalletBlockchain);
                  }}
                  IconComponent={(props) => {
                    return (
                      <IconChevronDown
                        {...props}
                        style={{
                          marginRight: '12px',
                          top: 'calc(50% - .7em)',
                          fill: dusk,
                        }}
                      />
                    );
                  }}
                  sx={{
                    backgroundColor: eggshell,
                    ':before': { border: 'none' },
                    '&.MuiSelect-iconStandard': { color: 'red' },
                  }}
                >
                  {availableBlockchain.map(({ label, value, icon }, i) => (
                    <MenuItem
                      key={i}
                      value={value}
                      disabled={label === 'Unknown'}
                    >
                      <Stack
                        gap={'14px'}
                        flexDirection={'row'}
                        alignItems={'center'}
                      >
                        <img
                          src={icon}
                          width={22}
                          height={22}
                          alt="chain-icon"
                        />

                        <Typography
                          sx={{
                            fontFamily: 'Rota',
                            fontSize: '16px',
                            fontWeight: 600,
                            color: midnight,
                          }}
                        >
                          {label}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}

                  <Typography
                    sx={{
                      fontFamily: 'Rota',
                      fontSize: '14px',
                      fontWeight: 700,
                      color: dusk,
                      textAlign: 'center',
                      my: 2,
                    }}
                  >
                    ⚡️Other blockchains coming soon
                  </Typography>
                </Select>
              </Stack>
            )}

            {/* Select Parser */}
            <Stack flexDirection="column" mb={1.5}>
              <InputLabel
                id="parser-info"
                sx={{
                  mb: 0.125,
                  fontFamily: 'Rota',
                  fontWeight: 700,
                  fontSize: '14px',
                }}
              >
                Select parser to emit this topic from
              </InputLabel>

              <Select
                labelId="parser-info"
                value={selectedParser.id}
                onChange={(e) => {
                  handleParserChange(e.target.value);
                }}
                IconComponent={(props) => {
                  return (
                    <IconChevronDown
                      {...props}
                      style={{
                        marginRight: '12px',
                        top: 'calc(50% - .7em)',
                        fill: dusk,
                      }}
                    />
                  );
                }}
                sx={{
                  backgroundColor: eggshell,
                  ':before': { border: 'none' },
                  '&.MuiSelect-iconStandard': { color: 'red' },
                }}
              >
                {[createNewParserDefault, ...existingParser].map(
                  ({ id, name, blockchainSource }) => {
                    const blockchain = availableBlockchain.find(
                      (item) => item.value == blockchainSource,
                    );
                    return (
                      <MenuItem key={id} value={id}>
                        <Stack
                          gap={'14px'}
                          flexDirection={'row'}
                          alignItems={'center'}
                        >
                          {blockchain && (
                            <img
                              src={blockchain.icon}
                              width={22}
                              height={22}
                              alt="chain-icon"
                            />
                          )}
                          <Typography
                            sx={{
                              fontFamily: 'Rota',
                              fontSize: '16px',
                              fontWeight: 600,
                              color: midnight,
                            }}
                          >
                            {name}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    );
                  },
                )}
              </Select>
            </Stack>

            {/* Parser Name */}
            {selectedParser.id === createNewParser ? (
              <Stack mb={1.5}>
                <InputLabel
                  id="parser-name"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    fontFamily: 'Rota',
                    color: dusk,
                    textAlign: 'left',
                    mb: 0.125,
                  }}
                >
                  Name Parser
                </InputLabel>

                <TextField
                  autoComplete="off"
                  value={parserName}
                  onChange={(e) => {
                    setParserName(e.target.value);
                  }}
                />
              </Stack>
            ) : null}

            {alertType === 'On-chain' ? (
              <CreateOnChainTopicStep2 />
            ) : (
              <CreateOffChainTopicStep2 />
            )}
          </Stack>
        </Stack>
      </Stack>
      {/* Filter Preview Card UI */}
      <Box sx={{ width: '367px', position: 'relative' }}>
        <FilterPreviewCard
          allowCustomInput={
            filter.userInputParams[
              isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
            ].allowCustomInput
          }
          name={
            uiConfigOverride.topicDisplayName
              ? uiConfigOverride.topicDisplayName
              : topicName
          }
          topicDisplayName={uiConfigOverride.topicDisplayName}
          thresholdDirection={filter.name}
          filterDescription={filter.description}
          thresholdValues={filter.userInputParams[
            isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
          ].options.map<string>((v) => v.toString())}
          thresholdType={
            filter.userInputParams[
              isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
            ].kind
          }
          prefixAndSuffixValue={prefixAndSuffixValue}
          customizedThresholdType={customizedThresholdType}
        />
      </Box>
    </Stack>
  );
};

export default OnOffChainTopicLayout;
