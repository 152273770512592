import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Box,
  Drawer,
  Toolbar,
  styled,
  Stack,
  Divider,
  SvgIconTypeMap,
  Typography,
} from '@mui/material';
import { RoleType, useAuthContext } from '../auth/AuthContext';
import { ConfirmDialog } from '../cardConfig/ConfirmDialog';
import { AppMainLogo } from 'src/AppMainLogo';
import { SocialMediaButtonGroup as Footer } from 'src/Footer';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CreateIcon from '@mui/icons-material/Create';
import CampaignIcon from '@mui/icons-material/Campaign';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import TuneIcon from '@mui/icons-material/Tune';
import InsightsIcon from '@mui/icons-material/Insights';
import LinkIcon from '@mui/icons-material/Link';
import { NavDrawerItem } from './NavDrawerItem';
import { LeftMenuButton } from './LeftMenuButton';
import {
  cloud,
  eggshell,
  heather,
  midnight,
  midnightLight,
  seaglassDarker,
} from 'src/constants/colors';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotifiEnv } from '@components/auth/NotifiEnvContext';
import {
  isCommunityManagerEnabled,
  isReportingEnabled,
} from 'src/pages/featureFlags';
import { DOCS_URL } from 'src/constants/urls';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import {
  BillingPlanType,
  useGetTenantBillingPlanQuery,
  useIs2faQuery,
} from 'src/services/gql/generated';
import { useAppContext } from 'src/context/AppContext';

//TODO: Check if we need to utilize StyledDrawer and inline CSS
const DRAWER_WIDTH = 360;

const StyledDrawer = styled(Drawer)`
  width: ${DRAWER_WIDTH}px;
  flex-shrink: 0;
  & .MuiDrawer-paper {
    width: ${DRAWER_WIDTH}px;
    background-color: ${midnight};
    border: none;
  }
`;

export enum ActiveMenuName {
  AlertManager,
  CardManagement,
  SendMessages,
  CommunityManager,
  Guide,
  Cards,
  Logout,
  Introduction,
  AccountSettings,
  Reporting,
  SmartLinks,
}

interface NavigationItem {
  name: string;
  Icon?:
    | OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  ActiveIcon?:
    | OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  activeName: ActiveMenuName;
  path: string;
  isEnabled: boolean;
  isOpenNewWindow?: boolean;
  subItems?: NavigationItem[];
}

export const NavDrawer: React.FC = () => {
  const { isAP2Enabled, loaded } = useFeatureFlag();
  const { isUnsavedModal, setIsUnsavedModal, setIsEditBroadCastPage, navPath } =
    useAppContext();
  const [activeMenu, setActiveMenu] = useState<ActiveMenuName>();
  const [open, setOpen] = useState<boolean>(false);
  const authState = useAuthContext();
  const location = useLocation();
  const { notifiEnv } = useNotifiEnv();
  const { data: billingPlanData } = useGetTenantBillingPlanQuery({
    variables: {},
  });
  const { data: is2faQueryData } = useIs2faQuery({
    variables: {},
  });
  const navigate = useNavigate();
  //Need to remove the (isSmartLinksActivated and isNotifiDeveloper) once the B.E is ready
  const isNotifiDeveloper = Boolean(localStorage.getItem('isNotifiDeveloper'));
  const isSmartLinksActivated = Boolean(
    localStorage.getItem('isSmartLinksActivated') ?? false,
  );

  const hasUserMessengerRole = useMemo(() => {
    if (authState.type === 'loggedIn') {
      return Boolean(authState.roles?.includes('UserMessenger'));
    }
    return false;
  }, [authState]);

  const hasAdminRole = useMemo(() => {
    if (authState.type === 'loggedIn') {
      return Boolean(authState.roles?.includes('TenantConfigAdmin'));
    }
    return false;
  }, [authState]);

  const isGuidePages = useCallback(() => {
    return location.pathname.includes('/guide');
  }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname === '/alert' ||
      location?.pathname === '/alert/configs' ||
      location?.pathname === '/alert/storage'
    ) {
      setActiveMenu(ActiveMenuName.AlertManager);
    } else if (location.pathname === '/community') {
      setActiveMenu(ActiveMenuName.CommunityManager);
    } else if (location.pathname === '/reporting') {
      setActiveMenu(ActiveMenuName.Reporting);
    } else if (location.pathname === '/settings') {
      setActiveMenu(ActiveMenuName.AccountSettings);
    } else if (location.pathname === '/smartlinks') {
      setActiveMenu(ActiveMenuName.SmartLinks);
    }
  }, [location.pathname]);

  if (authState.type !== 'loggedIn' && !isGuidePages()) return null;

  const topNavigationItems: (NavigationItem | null)[] = [
    isAP2Enabled
      ? {
          name: 'Alert Manager',
          Icon: NotificationsIcon,
          activeName: ActiveMenuName.AlertManager,
          path: '/alert',
          isEnabled: authState.type === 'loggedIn',
        }
      : {
          name: isAP2Enabled ? 'Alert Manager' : 'Card Management',
          Icon: NotificationsIcon,
          activeName: ActiveMenuName.CardManagement,
          path: '/cards',
          isEnabled: authState.type === 'loggedIn',
        },
    isAP2Enabled
      ? null
      : {
          name: 'Send Test Alerts',
          Icon: CreateIcon,
          activeName: ActiveMenuName.SendMessages,
          path: '/send',
          isEnabled: authState.type === 'loggedIn',
        },
    {
      name: 'Community Manager',
      Icon: CampaignIcon,
      activeName: ActiveMenuName.CommunityManager,
      path: '/community',
      isEnabled:
        authState.type === 'loggedIn' &&
        (hasUserMessengerRole || hasAdminRole) &&
        isCommunityManagerEnabled,
    },
    {
      name: 'Reporting',
      Icon: InsightsIcon,
      activeName: ActiveMenuName.Reporting,
      path: '/reporting',
      isEnabled: authState.type === 'loggedIn' && isReportingEnabled,
    },
    //Need to remove the (isSmartLinksActivated and isNotifiDeveloper) once the B.E is ready
    {
      name: 'Smart Links',
      Icon: LinkIcon,
      activeName: ActiveMenuName.SmartLinks,
      path: '/smartlinks',
      isEnabled:
        authState.type === 'loggedIn' &&
        isNotifiDeveloper &&
        isSmartLinksActivated,
    },
    {
      name: 'Account Settings',
      Icon: TuneIcon,
      activeName: ActiveMenuName.AccountSettings,
      path: '/settings',
      isEnabled:
        ((authState.type === 'loggedIn' &&
          Boolean(
            ['TenantConfigAdmin', 'TenantDeveloper'].some((role) =>
              authState.roles?.includes(role as RoleType),
            ),
          )) ||
          is2faQueryData?.totp2FAStatus.isRequired) ??
        false,
    },
  ];
  const lowerSubItems: NavigationItem[] = [
    {
      name: 'Introduction',
      activeName: ActiveMenuName.Introduction,
      path: '/guide',
      isEnabled: true,
    },
    {
      name: 'Cards',
      activeName: ActiveMenuName.Cards,
      path: '/guide/cards',
      isEnabled: true,
    },
  ];

  const lowerNavigationItems: NavigationItem[] = [
    {
      name: isAP2Enabled ? 'Docs & SDK' : 'Guides',
      Icon: StarBorderIcon,
      ActiveIcon: StarOutlinedIcon,
      activeName: ActiveMenuName.Guide,
      path: isAP2Enabled ? DOCS_URL : 'guide',
      isEnabled: true,
      isOpenNewWindow: true,
      subItems: isAP2Enabled ? [] : lowerSubItems,
    },
  ];

  const createNavigationItem = (item: NavigationItem) => {
    const { Icon, name, ActiveIcon, activeName, path, isOpenNewWindow } = item;

    return (
      <NavDrawerItem
        key={name}
        ActiveIcon={ActiveIcon}
        activeMenu={activeMenu}
        activeName={activeName}
        Icon={Icon}
        name={name}
        path={path}
        setActiveMenu={setActiveMenu}
        setOpen={setOpen}
        isOpenNewWindow={isOpenNewWindow}
      />
    );
  };

  return (
    <StyledDrawer variant="permanent">
      {notifiEnv === 'dev' ? (
        <Stack
          sx={{
            backgroundColor: midnightLight,
            position: 'relative',
            paddingY: '11px',
            paddingX: '44px',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Rota',
              color: seaglassDarker,
              fontSize: '14px',
              lineHeight: '21px',
              fontWeight: 700,
              textAlign: 'center',
              alignSelf: 'center',
            }}
          >
            You&apos;re logged into a dev instance
          </Typography>
        </Stack>
      ) : null}
      <Toolbar />
      <ConfirmDialog
        hideSecondaryAction={true}
        handleConfirm={() => setOpen(false)}
        open={open}
        ctaTitle="Got it"
        title="Please save before going to another page"
      />
      <AppMainLogo />

      <Stack
        marginTop="10px"
        paddingX="5%"
        height="100%"
        direction="column"
        justifyContent="space-between"
      >
        {loaded ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {topNavigationItems.map((item) =>
              item && item.isEnabled ? createNavigationItem(item) : null,
            )}
          </Box>
        ) : null}

        {billingPlanData?.billingPlan.billingPlanType ===
        BillingPlanType.FREE ? (
          <Stack
            sx={{
              px: 3,
              py: 2.5,
              backgroundColor: '#383B64',
              my: 4,
              borderRadius: '8px',
              rowGap: 1,
            }}
          >
            <Typography
              color={eggshell}
              fontWeight={800}
              fontSize={16}
              fontFamily={'Rota'}
              lineHeight={'20px'}
              letterSpacing={'-0.11px'}
            >
              Upgrade your account
            </Typography>
            <Typography
              color={heather}
              fontWeight={600}
              fontSize={14}
              fontFamily={'Rota'}
              lineHeight={'17.36px'}
            >
              Contact the Notifi Sales and Support team to send notifications.
              Your account is currently limited to 10 notifications per month.
            </Typography>

            <Stack direction={'row'} columnGap={1.5}>
              <Stack rowGap={0.5}>
                <Typography
                  color={heather}
                  fontWeight={700}
                  fontSize={12}
                  fontFamily={'Rota'}
                >
                  Email
                </Typography>
                <Typography
                  color={heather}
                  fontWeight={700}
                  fontSize={12}
                  fontFamily={'Rota'}
                >
                  TG
                </Typography>
              </Stack>
              <Stack rowGap={0.5}>
                <Typography
                  color={cloud}
                  fontWeight={700}
                  fontSize={14}
                  letterSpacing={'-0.11px'}
                  fontFamily={'Rota'}
                >
                  sales@notifi.network
                </Typography>
                <Typography
                  color={cloud}
                  fontWeight={700}
                  fontSize={14}
                  letterSpacing={'-0.11px'}
                  fontFamily={'Rota'}
                >
                  @missjeev and @derekh5
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        ) : null}

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Divider sx={{ borderBottomWidth: 3 }} />
          {lowerNavigationItems.map((lowerNavigationItem, index) => (
            // iterate through lower nav items and then check for sub items
            <React.Fragment key={index}>
              {lowerNavigationItem.isEnabled
                ? createNavigationItem(lowerNavigationItem)
                : null}
              {isAP2Enabled ? null : <Divider sx={{ borderBottomWidth: 3 }} />}
              {lowerNavigationItem.subItems
                ? lowerNavigationItem.subItems.map((subItem) => (
                    <>
                      {subItem.isEnabled ? createNavigationItem(subItem) : null}
                    </>
                  ))
                : null}
            </React.Fragment>
          ))}

          {authState.type === 'loggedIn' ? (
            <LeftMenuButton
              handleClick={() => {
                authState.logOut();
              }}
              isActive={false}
              size={'sm'}
              icon={<LogoutIcon sx={{ color: heather, width: '18px' }} />}
            >
              Log out
            </LeftMenuButton>
          ) : (
            false
          )}
          <Footer size="large" />
        </Box>
      </Stack>
      <ConfirmDialog
        subText="Are you sure you want to leave the page without saving?"
        ctaTitle="Leave Page"
        handleClose={() => {
          setIsUnsavedModal(false);
        }}
        handleConfirm={() => {
          setIsEditBroadCastPage(false);
          setIsUnsavedModal(false);
          if (navPath?.path !== '') {
            navigate(navPath?.path);
            setActiveMenu(navPath?.activeMenu || 0);
          }
        }}
        open={isUnsavedModal}
        title="Unsaved Changes"
        titleSx={{
          fontSize: '18px',
          color: midnight,
        }}
        showCloseIcon={true}
      />
    </StyledDrawer>
  );
};
